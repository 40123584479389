// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `b {
  font-weight: 900;
}

i {
  font-style: italic;
}

h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  margin: 20px 0 10px 0;
}

h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin: 20px 0 20px 0;
}

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
}

blockquote {
  margin: 20px 0;
  padding: 10px 20px;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
}

a {
  color: blue;
  text-decoration: underline;
}

ol {
  margin: 0 0 10px 20px;
  padding: 0;
  list-style-type: decimal;
}

ol li {
  margin: 10px 0;
}

ul {
  margin: 0 0 10px 20px;
  padding: 0;
  list-style-type: disc;
}

ul li {
  margin: 10px 0 0 0;
}

ul[data-checked="false"] {
  list-style-type: none;
  margin-left: 0px;
}

ul[data-checked="false"] li::before {
  content: "\\2713"; /* 체크 표시 */
  margin-right: 10px;
  color: gray;
}

ul[data-checked="true"] {
  list-style-type: none;
  /* color: red;  */
  margin-left: 0px;
}

ul[data-checked="true"] li::before {
  content: "\\2713"; /* 체크 표시 */
  margin-right: 10px;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/Posting/Style/Quill.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB,EAAE,UAAU;EAC5B,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB,EAAE,UAAU;EAC5B,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":["b {\n  font-weight: 900;\n}\n\ni {\n  font-style: italic;\n}\n\nh1 {\n  font-weight: bold;\n  font-size: 30px;\n  line-height: 35px;\n  margin: 20px 0 10px 0;\n}\n\nh2 {\n  font-weight: bold;\n  font-size: 20px;\n  line-height: 25px;\n  margin: 20px 0 20px 0;\n}\n\nu {\n  text-decoration: underline;\n}\n\ns {\n  text-decoration: line-through;\n}\n\nblockquote {\n  margin: 20px 0;\n  padding: 10px 20px;\n  background: #f9f9f9;\n  border-left: 10px solid #ccc;\n}\n\na {\n  color: blue;\n  text-decoration: underline;\n}\n\nol {\n  margin: 0 0 10px 20px;\n  padding: 0;\n  list-style-type: decimal;\n}\n\nol li {\n  margin: 10px 0;\n}\n\nul {\n  margin: 0 0 10px 20px;\n  padding: 0;\n  list-style-type: disc;\n}\n\nul li {\n  margin: 10px 0 0 0;\n}\n\nul[data-checked=\"false\"] {\n  list-style-type: none;\n  margin-left: 0px;\n}\n\nul[data-checked=\"false\"] li::before {\n  content: \"\\2713\"; /* 체크 표시 */\n  margin-right: 10px;\n  color: gray;\n}\n\nul[data-checked=\"true\"] {\n  list-style-type: none;\n  /* color: red;  */\n  margin-left: 0px;\n}\n\nul[data-checked=\"true\"] li::before {\n  content: \"\\2713\"; /* 체크 표시 */\n  margin-right: 10px;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
