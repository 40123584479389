// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login__9qzC4 {
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  height: 100vh;
  padding-top: 200px;
}

.Login_title__ADrdK {
  font-family: "Times New Roman", Times, serif;
  color: white;
  text-align: center;
  font-size: 50px;
}

.Login_outer_box__qTJDu {
  width: 100%;
  display: flex;
}

.Login_box__PXUNT {
  border: 1px;
  background-color: white;
  width: 100%;
  max-width: 500px;
  height: 200px;
  border-radius: 8px;
  position: relative;
  margin: 40px auto 0;
  padding: 40px 60px;
}

.Login_input__0CpTI {
  > p {
    text-align: left;
    font-size: 12px;
    margin: 0 0 2px 0;
  }

  > input {
    width: 100%;
    max-width: 500px;
    height: 30px;
    border: none;
    margin: 3px 0 18px 0;
    border-bottom: 1px solid #d9d9d9;
    /* border-radius: 4px; */
  }

  > input:focus {
    outline: none;
  }
}

.Login_loginBtn__HXsT4 {
  color: white;
  border-radius: 100px;
  width: 100%;
  max-width: 500px;
  height: 40px;
  border: none;
  margin: 25px 0 8px 0;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Login/Style/Login.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,4CAA4C;EAC5C,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,gCAAgC;IAChC,wBAAwB;EAC1B;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,oBAAoB;EACpB,gCAAgC;EAChC,eAAe;EACf,eAAe;AACjB","sourcesContent":[".login {\n  text-align: center;\n  margin-bottom: 10px;\n  width: 100%;\n  height: 100vh;\n  padding-top: 200px;\n}\n\n.title {\n  font-family: \"Times New Roman\", Times, serif;\n  color: white;\n  text-align: center;\n  font-size: 50px;\n}\n\n.outer_box {\n  width: 100%;\n  display: flex;\n}\n\n.box {\n  border: 1px;\n  background-color: white;\n  width: 100%;\n  max-width: 500px;\n  height: 200px;\n  border-radius: 8px;\n  position: relative;\n  margin: 40px auto 0;\n  padding: 40px 60px;\n}\n\n.input {\n  > p {\n    text-align: left;\n    font-size: 12px;\n    margin: 0 0 2px 0;\n  }\n\n  > input {\n    width: 100%;\n    max-width: 500px;\n    height: 30px;\n    border: none;\n    margin: 3px 0 18px 0;\n    border-bottom: 1px solid #d9d9d9;\n    /* border-radius: 4px; */\n  }\n\n  > input:focus {\n    outline: none;\n  }\n}\n\n.loginBtn {\n  color: white;\n  border-radius: 100px;\n  width: 100%;\n  max-width: 500px;\n  height: 40px;\n  border: none;\n  margin: 25px 0 8px 0;\n  border-bottom: 1px solid #d9d9d9;\n  cursor: pointer;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `Login_login__9qzC4`,
	"title": `Login_title__ADrdK`,
	"outer_box": `Login_outer_box__qTJDu`,
	"box": `Login_box__PXUNT`,
	"input": `Login_input__0CpTI`,
	"loginBtn": `Login_loginBtn__HXsT4`
};
export default ___CSS_LOADER_EXPORT___;
